.container{
    text-align: center;
}
.mainTitle{
    color: #FFF;
}
.text{
    color: #FFF;
}
.list{
    margin: 40px 0;
}
.list li{
    margin: 20px auto;
    width: 50%;
}
.tab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: var(--yellow);
    border-radius: 5px;
    color: #000;
    min-height: 30px;
    text-align: left;
    cursor: pointer;
}
.title{
    color: #FFF;
}
.description{
    font-size: 14px;
}
.caption{
    font-size: 12px;
    white-space: nowrap;
}
.image{
    margin: 30px auto;
    display: table;
}
.video{
    position: relative;
    z-index: 2;
    max-width: 500px;
    width: 100%;
    margin: 35px auto;
}
.videoFrame{
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
}
.videoFrame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@media(max-width: 767px) {
    .list li{
        width: 100%;
    }
}