.headerContainer{
    margin-top: 35px;
    width: 100%;
    height: 62px;
}
.container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 20px;
    z-index: 9998;
}
.container > div{
    flex: 1;
}
.logo{
    text-align: center;
}
.logo img{
    display: inline-block;
}
.button{
    color: var(--yellow);
    font-size: 24px;
    border: 1px solid;
    border-radius: 10px;
    float: right;
    padding: 5px 15px;
    cursor: pointer;
}
.title{
    color: var(--yellow);
    font-size: 24px;
    border: 1px solid;
    border-radius: 10px;
    float: right;
    padding: 5px 15px;
    margin-top: 45px;
}
@media(max-width: 599px){
    .headerContainer{
        margin-top: 100px;
        position: relative;
    }
}