.container{
    border-radius: 5px;
    padding: 10px;
    margin: 5px 5px 15px;
    position: relative;
    cursor: pointer;
    transition: all .2s ease-in-out;
    transform: scale(1);
    z-index: 1;
    background: rgba(236, 185, 86, 0.2);
}
.container:hover{
    transform: scale(1.05);
    z-index: 2;
    box-shadow: 0px 0px 22px 0px rgba(236, 185, 86, 0.75);
    -webkit-box-shadow: 0px 0px 22px 0px rgba(236, 185, 86,0.75);
    -moz-box-shadow: 0px 0px 22px 0px rgba(236, 185, 86,0.75);
}
.image{
    width: 100%;
}
.paragraph{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    text-transform: capitalize;
}
.title{}
.video{}
.button{
    border-radius: 5px;
    margin: 30px auto 0;
    display: table;
    padding: 5px 30px;
    background: var(--yellow);
    color: #FFF;
    font-size: 12px;
}