.container{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.shadow{
    background: rgba(0, 0, 0, 0.49);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.body{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    height: 100%;
}
.video{
    position: relative;
    z-index: 2;
    max-width: 500px;
    width: 100%;
}
.videoFrame{
    position: relative;
    padding-bottom: 56.25%;
    width: 100%;
}
.videoFrame iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}